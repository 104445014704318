<template lang="pug">
  #DeliveryDetail.box.sombra(v-if="delivery")
    h3.mb-3 Envío {{`#${delivery.id}`}}
    .box-form
        .material-icons.icon-input perm_identity
        b-form-group(
          label-for="store-input"
        )
          .input-title Cliente
          div.text {{ delivery.customerName ? delivery.customerName : 'No encontrado' }}
    hr.linea
    .box-form
        .material-icons.icon-input local_phone
        b-form-group(
          label-for="store-input"
        )
          .input-title Número Cliente
          div.text {{ delivery.customerPhone ? delivery.customerPhone : 'No encontrado' }}
    hr.linea
    .box-form
        .material-icons.icon-input email
        b-form-group(
          label-for="store-input"
        )
          .input-title Email  Cliente
          div.text {{ delivery.customerEmail ? delivery.customerEmail : 'No encontrado' }}
    hr.linea
    .box-form
        .material-icons.icon-input store
        b-form-group(
          label-for="store-input"
        )
          .input-title Tienda
          div.text {{ delivery.store ? delivery.store.name : 'No encontrado' }}
    hr.linea
    .box-form
        .material-icons.icon-input place
        b-form-group(
          label-for="store-input"
        )
          .input-title Dirección
          div.text {{ delivery.address ? (delivery.address.street != ''? delivery.address.street : 'Sin dirección') : 'Sin dirección'}}
    hr.linea
    .box-form
        .material-icons.icon-input place
        b-form-group(
          label-for="store-input"
        )
          .input-title Comuna
          div.text(style="text-transform: capitalize;") {{ delivery.address ? (delivery.address.commune!='' ?delivery.address.commune : 'Sin comuna') : 'Sin comuna'}}
    hr.linea
    .box-form(v-if="!hideDriver")
        .material-icons.icon-input perm_identity
        b-form-group(
          label-for="store-input"
        )
          .input-title Conductor
          div.text(style="text-transform: capitalize;") {{ delivery.driver ? delivery.driver.name + ' ' + delivery.driver.lastName : 'Sin conductor asignado'}}
    hr.linea(v-if="!hideDriver")
    .box-form
        .material-icons.icon-input calendar_today
        b-form-group(
          label-for="store-input"
        )
          .input-title Fecha
          div.text {{ delivery.date ? this.$moment(this.delivery.date.slice(0, 10)).format('DD-MM-YYYY') : 'Sin fecha'}}
    hr.linea
    .box-form
        .material-icons.icon-input store
        b-form-group(
          label-for="store-input"
        )
          .input-title Estado del Envío
          div.text(style="text-transform: capitalize;") {{ delivery.state ? delivery.state : 'Por entregar'}}
    hr.linea
    .box-form
        .material-icons.icon-input event_available
        b-form-group(
          label-for="store-input"
        )
          .input-title Última Actualización de Estado
          div.text(style="text-transform: capitalize;") {{ this.$moment(delivery.lastUpdateState || delivery.createdAt).format('DD-MM-YYYY HH:mm') }}
    hr.linea
    .box-form
        .material-icons.icon-input assignment
        b-form-group(
          label-for="store-input"
        )
          .input-title Comentarios
          div.text {{ delivery.commentary ? delivery.commentary : 'Sin comentario' }}
    hr.linea
    div(v-if="delivery.images ")
        div(v-for="image in delivery.images")
          b-img(:src="`${image.url}`" fluid)
    div.text(v-if="!delivery.images || delivery.images.length == 0") Sin imágenes adjuntas
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    hideDriver: Boolean
  },
  methods: {
    ...mapActions(['getDelivery'])
  },
  computed: {
    ...mapGetters(['delivery'])
  },
  async created () {
    await this.getDelivery({ id: this.$route.params.id })
  }
}
</script>
