<template lang="pug">
div
  //- .row.mb-4.mt-2(v-if="!newDelivery")
  //-   .col.d-flex.flex-column
  //-     .mr-2.flex-row.d-flex(v-if="notValidDay")
  //-       .material-icons.icon-color.mr-2 sentiment_very_dissatisfied
  //-       span.d-block.mt-1 Lamentablemente el dia de mañana no podremos realizar retiros
  //-     .mt-2.flex-row.d-flex(v-if="lateHours")
  //-       .material-icons.icon-color.mr-2 watch_later
  //-       span Para poder crear envíos, tiene que ser entre las 9:00 am y 10:00 pm
  //-     .mt-2.flex-row.d-flex(v-if="isException && exceptions.length > 0")
  //-       .material-icons.icon-color.mr-2 info
  //-       span(v-if="!noExceptionAllowed") {{exceptions[0].comment}}
  //-       div(style="font-weight:bold; text-align: center;").d-flex.flex-column(v-if="noExceptionAllowed")
  //-         span De momento no tenemos ruta para la fecha seleccionada.
  //-         span Nuestra atención por Instagram es de lunes a viernes de 9 a 23 horas.
  //-         span Nuestras rutas son de Martes a Sabádo.
  #upload-deliveries-from-exel()
    .loading(v-if="loading")
      .text-center.mb-2 {{ loadingText }}
      b-spinner.m-auto
    div(v-else-if="step===1").text-center
      input.d-none(ref="inputFile" type="file" name="file" @change="setFile")
      b-button(@click="() => this.$refs.inputFile.click()") Cargar archivo
        .material-icons upload
    div(v-else-if="step===2")
      .mb-2 Se encontraron varias paginas, selecciona las que deseas ingresar y has click en Siguente
      b-form-checkbox-group(v-model="sheets" :options="sheetsOptions" stacked)
      b-button(@click="step=3") Siguente
    div(v-else-if="step===3")
      div Se cargaron correctamente {{deliveries.length}} envíos
      div.text-danger(v-if="errorCount > 0") {{errorCount}} datos nulos:
      div.error-messages(v-if="deliveryErrors.length > 0")
        span.error-row.text-danger(v-for="error in deliveryErrors") {{ `Error en fila ${error.row}, ${error.column} ${errorText[error.error] || error.error}` }}

      //- b-button(@click="onSubmit") Guardar envíos
    div(v-else-if="step===4")
      div {{ loadingText }}
</template>

<script>
import xlsxFile from 'read-excel-file'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      loadingText: 'Cargando archivo',
      step: 1,
      file: null,
      sheets: [],
      sheetsOptions: [],
      loading: false,
      deliveries: [],
      deliveryErros: [],
      errorCount: 0,
      lateHours: false,
      newDelivery: false,
      isException: false,
      notValidDay: false,
      noExceptionAllowed: false,
      errorText: {
        required: 'no puede estar vacío'
      },
      schema: {
        Tienda: {
          prop: 'storeName',
          type: String,
          required: true
        },
        'Fecha Ingreso': {
          prop: 'date',
          type: Date
        },
        Nombre: {
          prop: 'customerName',
          type: String,
          required: true
        },
        Direccion: {
          prop: 'address',
          type: String,
          required: true
        },
        Comuna: {
          prop: 'commune',
          type: String,
          required: true
        },
        Telefono: {
          prop: 'customerPhone',
          type: String
        },
        Correo: {
          prop: 'customerEmail',
          type: String
        },
        Conductor: {
          prop: 'driverName',
          type: String
        }
      }
    }
  },
  methods: {
    ...mapActions(['bulkCreateDelivery', 'checkWithdrawalHour', 'listExceptions']),
    async onSubmit () {
      this.loading = true
      this.loadingText = 'Registrando los envíos'
      const response = await this.bulkCreateDelivery({ data: this.deliveries })
      if (response.status === 200) {
        this.makeSuccessToast('Los envíos se registraron correctamente')
        this.loadingText = 'Los envíos se registraron correctamente'
        this.$emit('done')
      } else if (response.status >= 400) {
        this.loadingText = 'Error al registrar envíos'
        this.makeDangerToast(response.data.message || 'Error al crear los envios')
      }

      this.step = 4
      this.loading = false
    },
    setFile (event) {
      this.file = event.target.files[0]
    },
    setNewDelivery () {
      /*
        Siempre se agenda el envío para el dia de mañana,
        los unicos dias que no se pueden agendar son domingos y lunes
        el horario es de 9:00-22:00
      */
      const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1)) // Se obtiene la fecha del dia siguiente
      const day = tomorrow.getDay()
      /*
        A menos que haya una exepción no se puede agendar fechas para el domingo o el lunes
        en dias UTC el domingo es 0 y el lunes es 1
      */
      const restrictDays = (day === 1 || day === 0)
      if (this.lateHours) this.newDelivery = false
      if (restrictDays) {
        // Si la fecha no esta dentro de los dias no se puede agendar
        this.notValidDay = true
        this.newDelivery = false
      }
      if (!this.lateHours && day > 1 && !restrictDays) this.newDelivery = true
      if (this.exceptions.length > 0) {
        // Hay excepciones
        // Las excepciones  que se toman en cuenta son para el dia siguiente del envío.
        if (this.exceptions[0].allowRemove && !this.lateHours) {
          /*
            Si la excepcion permite agendar fecha para un dia que no se pueda y
            esta dentro el horario permitido se podra agendar
          */
          this.newDelivery = true
          this.notValidDay = false
        } else if (!this.exceptions[0].allowRemove) {
          // Excepcion que no permite agendar fecha para ese dia.
          this.noExceptionAllowed = true
          this.notValidDay = true
          this.newDelivery = false
          this.isException = true
        }
      }
    },
    getDeliveryDate () {
      const currentDate = this.$moment.tz('America/Santiago')
      const hours = currentDate.hours()
      // const minutes = currentDate.minutes()
      if (Number(hours) >= 13) {
        const tomorrow = currentDate.add(1, 'days')
        // console.log('El delivery se enviará mañana: ', tomorrow.format('DD/MM/YYYY'))
        return tomorrow
        // return currentDate.format('YYYY-MM-DD')
      } else {
        // console.log('El delivery se enviará hoy: ', currentDate.format('DD/MM/YYYY'))
        return currentDate
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'exceptions'])
  },
  async created () {
    if (this.user.role === 'admin') {
      this.lateHours = false
      this.newDelivery = true
      console.log(this.lateHours, 'late hours', this.newDelivery, 'Delivery')
    }
    if (this.user.role === 'store') {
      // this.form.storeId = this.user.storeId
      // this.form.date = this.getDeliveryDate()
      // Consulta al back para verificar horario de 9am a 22pm
      // const resp = await this.checkWithdrawalHour()
      // this.form.date = this.$moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD')
      // this.lateHours = resp.response
      // console.log(resp)
      await this.listExceptions({
        params: {
          date: this.$moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD')
        }
      })
      this.setNewDelivery()
    }
  },
  mounted () {
    this.$emit('showSaveBtn', false)
  },
  watch: {
    async file () {
      this.loading = true
      const sheets = await xlsxFile(this.file, { getSheets: true })
      this.sheetsOptions = sheets.reduce((acum, item) => acum.concat({
        value: item.name,
        text: item.name
      }), [])
      this.step = 2
      this.loading = false
    },
    async step () {
      if (this.step === 2 && this.sheetsOptions.length === 1) {
        this.sheets = [this.sheetsOptions[0].value]
        this.step = 3
      }
      if (this.step === 3) {
        if (!this.loading) {
          this.loading = true
          let deliveries = []
          let count = 0
          this.loadingText = `Cargando plantilla (${count} / ${this.sheets.length})`
          if (this.user.role === 'store') {
            delete this.schema.Tienda
            delete this.schema['Fecha Ingreso']
            delete this.schema.Conductor
          }
          for (const sheet of this.sheets) {
            const { rows, errors } = await xlsxFile(this.file, { schema: this.schema, sheet })
            deliveries = deliveries.concat(rows)
            if (this.user.role === 'store') {
              deliveries = deliveries.map((d) => {
                return {
                  ...d,
                  storeName: this.user.name,
                  storeId: this.user.storeId,
                  // date: this.$moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD'),
                  date: this.getDeliveryDate().format('YYYY-MM-DD'),
                  driverName: null
                }
              })
            }
            count++
            this.loadingText = `Cargando plantilla (${count} / ${this.sheets.length})`
            // console.log(errors, 'este es el error')
            const errorsFiltered = []
            for (const error of errors) {
              // if (error.value !== null) {
              //   errorsFiltered.push(error)
              // }
              if (errors.filter((e) => e.row === error.row).length < Object.values(this.schema).filter((value) => value.required).length) {
                errorsFiltered.push(error)
              }
            }
            // console.log(rows, 'ROWS')
            // this.errorCount += errors.length
            // console.log(errorsFiltered, 'Error filtered')
            this.errorCount += errorsFiltered.length
            this.deliveryErrors = errorsFiltered
            if (!errorsFiltered.length) {
              this.$emit('errors', false)
            } else {
              this.$emit('errors', true)
            }
          }
          if (!deliveries.length) {
            this.$emit('errors', true)
          }
          if (deliveries.length > 0) {
            this.$emit('showSaveBtn', true)
          }
          this.deliveries = deliveries
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#upload-deliveries-from-exel {
  .loading {
    display: flex;
    justify-content: center;
    height: 200px;
    flex-direction: column;
  }
}
.error-row {
  display: flex;
  justify-content: start;
  padding-left: 2rem;
}
</style>
